:root {
  --white: #ffffff;
  --blue: #272a31;
  --l-blue: #e3edfb;
  --grey: #c5cbd4;
  --dgrey: #5b6069;
  --dblue: #7c818c;
  --primary-blue: #1d66ff;
  --pblue: #001aff;
  --berlinBlue: #0f2a43;
  --red: red;
}
