html,
body,
p,
span {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  font-family: "Poppins", sans-serif !important;
}
body {
  overflow-y: hidden;
}
.ant-layout {
  background: inherit !important;
}
.ant-input,
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: 1px solid var(--dblue);
  min-height: 37px;
}
.ant-form-item {
  margin: 0 0 14px !important;
}
.otp-input {
  width: 40px !important;
  height: 40px !important;
  border-radius: 0 !important;
  border: none !important;
  border-bottom: 2px solid var(--dblue) !important;
}
.ng-otp-input-wrapper .otp-input:not(:last-child) {
  margin-right: 12px !important;
  margin-top: 30px;
}
@media screen and (max-width: 500px) {
  .otp-input {
    width: 22px !important;
    height: 22px !important;
  }
}
