/* You can add global styles to this file, and also import other style files */
@import "assets/styles/commom.scss";
@import "assets/styles/reset.scss";
@import "assets/styles/typography.scss";
@import "assets/styles/utility.scss";
@import "assets/styles/variables.scss";
@import "codemirror/lib/codemirror.css";
@import "codemirror/addon/hint/show-hint.css";
.ant-btn-primary {
  border-color: #1d66ff !important;
  background: #1d66ff !important;
}
.ant-menu-item ant-menu-item-selected {
  font-weight: 500 !important;
}

html, body {
  height: 100%;
  margin: 0;
}
