body {
  background: linear-gradient(30deg, #ede3fb 0%, #e3edfb 100%);
}

.title-text {
  font-size: 22px;
  font-weight: 700;
  color: var(--blue);
  line-height: 36px;
}
.maxheight {
  margin-top: 35px;
}
.maxheight1 {
  margin-top: 0;
}
.cPointer {
  cursor: pointer;
}
.m-auto {
  margin: auto !important;
}
button {
  height: 34px;
  border-radius: 4px;
  background: var(--primary-blue);
  border: none;
  color: #fff;
  margin-top: 5px;
  text-transform: uppercase;
  cursor: pointer;
  min-width: 80px;
}
.save {
  height: 34px;
  border-radius: 4px;
  min-width: 80px;
}
.cancel {
  height: 34px;
  border-radius: 4px;
  min-width: 97px;
}
.ant-btn {
  height: 34px !important;
}
.ant-btn-primary {
  box-shadow: none !important;
}
.d-flex {
  display: flex;
}
.text-center {
  text-align: center !important;
}
.visitedClass {
  border: 2px solid var(--primary-blue);
}
.notVisited {
  border: inherit;
}
.w100 {
  width: 100%;
}
.ant-form-item-label > label {
  font-size: 11px !important;
  font-weight: 400 !important;
}
.justifyCenter {
  justify-content: center;
}
.justifyRight {
  justify-content: right;
}

@media screen and (max-width: 991px) {
  .maxheight {
    margin-top: 80px;
  }
  .maxheight1 {
    margin-top: 80px;
  }
}
::ng-deep {
  ::-webkit-scrollbar {
    background-color: transparent !important;
    height: 8px;
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #9bc4e9 !important;
    height: 8px;
    width: 8px;
  }
}
.addmore {
  background-color: #c2d6ff;
  border: 1px solid #1d66ff;
  color: #1d66ff;
  text-align: center;
  border-radius: 5px;
  width: 110px;
  cursor: pointer;
}
//input with icon
.inputIconContainer{
  .ant-input, .ant-input-affix-wrapper{
    font-size: 12px!important;
  }
}
//select option style
.dropDownContainer{  
  .ant-select-item-option-content{
    font-size: 12px!important;
  }
  .ant-select-item, .ant-select-item-empty{
    font-size: 12px;
  }
  
}
//select input style
.selectContainer{
  .ant-select{
    font-size: 12px!important;
  }
}

.withSearch {
  ::ng-deep {
    .cdk-virtual-scrollable {
      margin-top: 45px !important;
      max-height: 211px !important;
    }
  }
}

// changed on 09/02/2024 - HD
.ant-input-affix-wrapper>input.ant-input {
  font-size: 12px!important;
}
.ant-dropdown-menu-item {
  font-size: 12px !important;
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  font-size: 12px !important;
}
.ant-select-item-option-content, input {
  font-size: 12px !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector, .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  font-size: 12px !important;
}
.ant-tabs-tab-btn {
  font-size: 12px !important;
}

//popup style
.ant-modal-content{
  border-radius: 8px;
}
.ant-modal-header{
  border-radius: 8px 8px 0 0;
}
//border for input  5px
.ant-input, .ant-select:not(.ant-select-customize-input) .ant-select-selector{
  border-radius: 5px;
}
.ant-input-group .ant-input-affix-wrapper:not(:last-child), .ant-input-group-addon:first-child, .ant-input-group-addon:first-child .ant-select .ant-select-selector, .ant-input-group>.ant-input-affix-wrapper:not(:last-child) .ant-input, .ant-input-group>.ant-input:first-child, .ant-input-group>.ant-input:first-child .ant-select .ant-select-selector
{
  border-radius: 5px;

}
.ant-picker{
  border: 1px solid var(--dblue);
  border-radius: 5px;
}
// common disable date picker 
.ant-picker.ant-picker-disabled{
  background: initial!important;
  border:1px solid var(--dblue)!important;
  color: rgba(0, 0, 0, .85)!important;
}
.ant-picker-input>input[disabled]{
  color: rgba(0, 0, 0, .85)!important;
}
.ant-picker.ant-picker-disabled .ant-picker-suffix{
  color: rgba(0, 0, 0, .85)!important;
}
// .buttonDefault{
//   border: 1px solid #1D66FF;
//   color: #1D66FF;
// }
.reverse_ant-select-dropdown {
  .ant-select-dropdown > div {
    display: flex !important;
    flex-direction: column-reverse !important;
  }
}

//remove status field border
.removeBorder {
  .ant-input, .ant-select:not(.ant-select-customize-input) .ant-select-selector{
    border: none;
  }
}
//disable select
.disableSelect{
  .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector{
    background-color: white;
  }
}
.status_color_container{
  .ant-input, .ant-select:not(.ant-select-customize-input) .ant-select-selector{
    min-height: initial!important;
  }
  .ant-input, .ant-select:not(.ant-select-customize-input) .ant-select-selector{
    min-height: initial!important;
  }
} 


.main_logo{
  width: 220px;
  height: 80px;
}
.side_logo{
  width: 40px;
  height: 40px;
}
.cPointer {
  cursor: pointer;
}
.w-100{
  width: 100%;
}
.available_space{
  height: 100%;
  width: 100%;
}

//button style
.save_btn_style{
  text-align: center !important;
  cursor: pointer!important;
  font-size: 10px!important;
  font-weight: 700!important;
  height: 34px!important;
  border-radius: 4px;
  background: var(--primary-blue)!important;
  border: none!important;
  color: #fff!important;
  text-transform: uppercase!important;
  box-shadow: 0 2px 0 rgba(0, 0, 0, .015)!important;
  margin: 0!important;

}
.save_btn_style:hover {
  text-align: center !important;
  cursor: pointer!important;
  font-size: 10px!important;
  font-weight: 700!important;
  height: 34px!important;
  border-radius: 4px!important;
  background: var(--primary-blue)!important;
  border: none!important;
  color: #fff!important;
  text-transform: uppercase!important;
  box-shadow: 0 2px 0 rgba(0, 0, 0, .015)!important;
  margin: 0!important;
}
.cancel_btn_style{
  text-align: center !important;
  cursor: pointer!important;
  font-size: 10px!important;
  font-weight: 700!important;
  height: 34px!important;
  border-radius: 4px!important;
  text-transform: uppercase;
  background: #fff!important;
  color: #1D66FF!important;
  border: 1px solid #1D66FF!important;
  box-shadow: 0 2px 0 rgba(0, 0, 0, .015);
  margin: 0;
} 
.cancel_btn_style:hover{
  text-align: center !important;
  cursor: pointer!important;
  font-size: 10px!important;
  font-weight: 700;
  height: 34px!important;
  border-radius: 4px!important;
  text-transform: uppercase!important;
  background: #fff!important;
  color: #1D66FF!important;
  border: 1px solid #1D66FF!important;
  box-shadow: 0 2px 0 rgba(0, 0, 0, .015)!important;
  margin: 0!important;
} 
.right_end_btn_container{
  display: flex;
  column-gap: 15px;
  justify-content: end;
  align-items: center;
  padding-top: 10px;
}
.error_text_style{
  color: red;
  font-size: 12px;
}
.red_color{
  color: red;
}
.lable_text_style{
  font-size: 11px !important;
  font-weight: 400 !important;
  padding: 0!important;
}
.w_100{
  width: 100%;
}

nz-form-control{
  padding: 0!important;
}
.c_pointer{
  cursor: pointer;
}
.hr_style{
  background-color: #D2D2D2;
  border-radius: 5px;
  opacity: 0.5;
  width: 100%;
  margin: 0!important;
}
.info_tool_tip_container{
  padding-left: 12px !important;
  .ant-tooltip-inner {
    background-color: white!important;
    border-radius: 8px!important;
    box-shadow:
      0px 4px 6px -2px rgba(16, 24, 40, 0.03),
      0px 12px 16px -4px rgba(16, 24, 40, 0.08)!important;
    font-family: Poppins!important;
    font-size: 10px!important;
    font-style: normal!important;
    font-weight: 400!important;
    line-height: normal!important;
    color: black!important;
    text-align: center!important;
  }
  .ant-tooltip-arrow-content {
    --antd-arrow-background-color: linear-gradient(to right bottom, white, white)!important;
  }
}
.c_pointer{
  cursor: pointer;
}
.custom-priority{
  width: 100%;
}
.select-option_container{
  margin-top: 6px!important;
}