//color
.bgColor {
  background: linear-gradient(30deg, #ede3fb 0%, #e3edfb 100%);
  // background-color: var(--l-blue);
}
.lb-bgcolor {
  background-color: var(--l-blue);
}
.dgrey-text {
  color: var(--dgrey);
}
.blue-text {
  color: var(--pblue);
}
.textcolor {
  color: var(--blue);
}
.blueColor {
  color: var(--primary-blue);
}
.whitebg {
  background-color: var(--white);
}
.berlinBluecolor {
  color: var(--berlinBlue);
}
.redColor {
  color: var(--red);
}
//font-weight
.fw-100 {
  font-weight: 100;
}
.fw-200 {
  font-weight: 200;
}
.fw-300 {
  font-weight: 300;
}
.fw-400 {
  font-weight: 400;
}
.fw-500 {
  font-weight: 500;
}
.fw-600 {
  font-weight: 600;
}
.fw-700 {
  font-weight: 700;
}
.fw-800 {
  font-weight: 800;
}
.fw-900 {
  font-weight: 900;
}

//font-size
.f-8 {
  font-size: 8px;
}
.f-9 {
  font-size: 9px;
}
.f-10 {
  font-size: 10px;
}
.f-11 {
  font-size: 11px;
}
.f-12 {
  font-size: 12px;
}
.f-13 {
  font-size: 13px;
}
.f-14 {
  font-size: 14px;
}
.f-16 {
  font-size: 16px;
}
.f-18 {
  font-size: 18px;
}
.f-20 {
  font-size: 20px;
}
.f-22 {
  font-size: 22px;
}
.f-24 {
  font-size: 24px;
}

//line-height
.lh-16 {
  line-height: 16px;
}
.lh-18 {
  line-height: 18px;
}
.lh-21 {
  line-height: 21px;
}
.lh-24 {
  line-height: 24px;
}
.lh-27 {
  line-height: 27px;
}
.lh-36 {
  line-height: 36px;
}

//margin
.mt-10 {
  margin-top: 10px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-24 {
  margin-top: 24px;
}
.mt-30 {
  margin-top: 30px;
}
.mt-40 {
  margin-top: 40px;
}
.mt-50 {
  margin-top: 50px !important;
}
.mt-70 {
  margin-top: 70px;
}
.mb-50 {
  margin-bottom: 50px;
}

//padding
.pl5 {
  padding-left: 5%;
}
.pr5 {
  padding-right: 5%;
}
.mt15p {
  padding-top: 15%;
}
.mt20 {
  margin-top: 20%;
}

//width
.w80 {
  width: 80px;
}
